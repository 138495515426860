<template>
    <div class="wrapper-content-page">
        <div class="block-info">
            <div class="block-info__logo">
                <img
                    :src="
                        require('@/assets/images/main/home-page/Capy_sample.png')
                    "
                    data-aos="fade-down"
                    @click="$router.push('/')"
                />
            </div>

            <h3 class="block-info__title" data-aos="fade-right">
                Capy Terms Of Service
            </h3>

            <div class="block-info__content">
                <p class="desc-text desc-text--note" data-aos="fade">
                    *Last updated: 27 Aug 2021*
                </p>

                <h3 class="title-text" data-aos="fade-right">
                    Welcome to Capy!
                </h3>

                <p class="desc-text" data-aos="fade">
                    Please read these Terms of Service (“<b>Terms</b>”)
                    carefully before you access and/or use our website at
                    capy.sg and/or the Capy mobile application (including any
                    upgrades thereto) (“<b>App</b>”).
                </p>

                <p class="desc-text" data-aos="fade">
                    These Terms of Service set out the terms and conditions on
                    which 55 Minutes Pte. Ltd. (UEN No. 201003881C) a company
                    duly incorporated in Singapore (“<b>we</b>”, “<b>us</b>” or
                    “<b>our</b>”) offer you access to and use of the Website,
                    App and associated content, products or services that we may
                    provide from time to time through the Website and/or App
                    (collectively “<b>Service</b>”).
                </p>

                <p class="desc-text" data-aos="fade">
                    We may also publish on the Website from time to time, our
                    policies and procedures relating to the use of the Service,
                    including our
                    <router-link to="/privacy">Privacy Policy</router-link>.
                    (collectively “<b>Policies</b>”).
                </p>

                <p class="desc-text" data-aos="fade">
                    Unless we state otherwise, if there is any conflict or
                    inconsistency between these Terms of Service and any of the
                    Policies, these Terms of Service shall prevail, save with
                    respect to any provisions relating to personal data, for
                    which the Privacy Policy shall prevail.
                </p>

                <p class="desc-text" data-aos="fade">
                    By accessing or using the Service, you are deemed to have
                    read, understood and agreed to be bound by these Terms of
                    Service and the Policies (collectively “<b>Terms</b>”),
                    which together form the entire agreement
                    (“<b>Agreement</b>”) between us in relation to your access
                    to and use of the Service.
                </p>

                <p class="desc-text" data-aos="fade">
                    We may revise these Terms at any time in our sole discretion
                    by posting the revised version(s) on our Website. You agree
                    to be bound by all such changes to the Terms.
                </p>

                <p class="desc-text" data-aos="fade">
                    If you disagree with these Terms, or any changes to the
                    Terms, you must stop using the Service. It is your
                    responsibility to check the Terms periodically when you use
                    the Service. Your continued use of the Service after changes
                    to the Terms have been made will constitute your acceptance
                    of the revised Terms.
                </p>

                <h3 class="title-text" data-aos="fade-right">1. THE SERVICE</h3>

                <p class="desc-text" data-aos="fade">
                    <b class="is-italic">Location</b>. The Service is intended
                    for use in Singapore. It is not intended for distribution to
                    or use by any person in any country where such distribution
                    to or use would be contrary to law or regulation or which
                    would subject us to any registration or other requirements
                    within such country. If you choose to access the Service
                    from other countries, you acknowledge that you do so at your
                    own risk and that you are solely responsible for compliance
                    with local laws, if and to the extent applicable.
                </p>

                <p class="desc-text" data-aos="fade">
                    <b class="is-italic">Features</b>. Service features and
                    functionalities are subject to change, and we do not
                    guarantee that any specific feature, component and/or
                    content will always be available on the Service. For current
                    information on the Service (including information on
                    available features and user guides) please refer to our
                    Website.
                </p>

                <p class="desc-text" data-aos="fade">
                    <b class="is-italic">Access</b>. You may browse the Website
                    without registering for an account. However, to start using
                    the App, you will have to download the App onto your
                    compatible mobile device and sign up for a user account.
                </p>

                <p class="desc-text" data-aos="fade">
                    <b class="is-italic">Availability</b>. We are not obliged to
                    provide any maintenance and support services (including
                    maintenance releases, patches, fixes or extensions), or to
                    provide any updates or upgrades to the Service. We may
                    interrupt or suspend your access to the Service from time to
                    time, for routine, scheduled or unplanned maintenance or
                    downtime, backups, updates, upgrades or other reasons. Such
                    interruptions may result in temporary unavailability of our
                    Service. We will attempt to notify you in advance of any
                    scheduled downtime but you acknowledge that this may not be
                    reasonably practicable for unscheduled downtime (including
                    emergency maintenance) which may be due to reasons beyond
                    our control.
                </p>

                <p class="desc-text" data-aos="fade">
                    <b class="is-italic">Updates</b>. We may from time to time
                    issue updates to the App. Depending on the update, you may
                    not be able to use the App and/or your Account(s) until you
                    have accepted any new or additional terms and conditions of
                    use.
                </p>

                <p class="desc-text" data-aos="fade">
                    <b class="is-italic">Fees</b>. Access to the Website and use
                    of the App is free for now, but we do not guarantee that it
                    will remain free of charge forever. We may offer optional
                    features in the future that we may charge for, and generally
                    reserve the right to impose such fees in the future as we
                    may see fit upon notice to you.
                </p>

                <div class="desc-text" data-aos="fade">
                    <span>
                        <b class="is-italic">Management</b>. We reserve the
                        right in our sole discretion (but shall not be obliged)
                        to:
                    </span>

                    <p class="is-flex">
                        <span class="mr-4">(a)</span>
                        <span
                            >monitor your access to and/or use of the Service
                            for violations of this Agreement;</span
                        >
                    </p>
                    <p class="is-flex">
                        <span class="mr-4">(b)</span>
                        <span
                            >take appropriate legal action against you or any
                            person who, in our opinion, has violated this
                            Agreement, including by pursuing civil, criminal,
                            and injunctive redress and reporting you or such
                            person to law enforcement authorities;</span
                        >
                    </p>
                    <p class="is-flex">
                        <span class="mr-4">(c)</span>
                        <span
                            >without limitation, notice or liability, remove
                            from the Service or otherwise disable all files and
                            content that are excessive in size or are in any way
                            burdensome to our systems;</span
                        >
                    </p>
                    <p class="is-flex">
                        <span class="mr-4">(d)</span>
                        <span
                            >block certain IP address(es) from accessing the
                            Service; and/or</span
                        >
                    </p>
                    <p class="is-flex">
                        <span class="mr-4">(e)</span>
                        <span
                            >otherwise manage the Service in a manner in order
                            to protect our rights and property and those of
                            others and to facilitate the proper provision of the
                            Service and the functioning of the Service.</span
                        >
                    </p>
                </div>

                <h3 class="title-text" data-aos="fade-right">
                    2. YOUR ACCOUNT
                </h3>

                <p class="desc-text" data-aos="fade">
                    “<b class="is-italic">Account</b>” means any user account
                    for the use of the App, including a Parent Account and/or
                    linked Child Account as the context requires. References to
                    “<b class="is-italic">your Account</b>” under these Terms
                    shall, refer to your Parent Account and your linked Child
                    Account(s).
                </p>

                <p class="desc-text" data-aos="fade">
                    <b class="is-italic">Eligibility</b>. We are entitled, in
                    the exercise of our sole discretion, to refuse your
                    application for an Account, and/or to change the eligibility
                    criteria for an Account at any time.
                </p>

                <p class="desc-text" data-aos="fade">
                    <b class="is-italic">Minimum age</b>. Users under the age of
                    eighteen (18) are required to obtain the full consent of
                    their parents or legal guardians to use and register for an
                    Account on the Service.
                </p>

                <p class="desc-text" data-aos="fade">
                    <b class="is-italic">Parent Account</b>. To set up your
                    family on the App, you need to first create an adult account
                    (“<b>Parent Account</b>”) before creating account(s) for
                    your child(ren)/ ward(s) (“<b>Child Account(s)</b>”). You
                    must be above the age of eighteen (18) to register for a
                    Parent Account.
                </p>

                <p class="desc-text" data-aos="fade">
                    As the registered user of a Parent Account, you can view,
                    manage, interact with Child Account(s) linked to your Parent
                    Account
                </p>

                <p class="desc-text" data-aos="fade">
                    <b class="is-italic">Child Account</b>. Once you have
                    created your Parent Account, your child/ward can create a
                    linked Child Account by downloading the App onto their
                    mobile device and scanning the unique QR code associated
                    with your Parent Account, which will prompt them to register
                    for a Child Account linked to your Parent Account.
                </p>

                <p class="desc-text" data-aos="fade">
                    <b class="is-italic">Consent</b>. You acknowledge that by
                    providing consent as the parent or legal guardian to your
                    child or ward under the age of eighteen (18) to use the
                    Service, both you and your child or ward are agreeing to be
                    bound by the Terms.
                </p>

                <div class="desc-text" data-aos="fade">
                    <span>
                        In particular, by inviting and/or allowing a user create
                        a Child Account through the use of your QR code:</span
                    >

                    <p class="is-flex">
                        <span class="mr-4">-</span>
                        <span
                            >you represent and warrant that you are his/her
                            parent/legal guardian or an agent of his/her
                            parent/legal guardian with the requisite authority
                            to do so (and to provide the consents hereunder);
                            and</span
                        >
                    </p>
                    <p class="is-flex">
                        <span class="mr-4">-</span>
                        <span
                            >you are deemed to have consented to his/her use of
                            the Service and agreement to the Terms; and</span
                        >
                    </p>
                    <p class="is-flex">
                        <span class="mr-4">-</span>
                        <span
                            >you are deemed to have consented to our use of
                            his/her personal data in accordance with our
                            <router-link to="/privacy"
                                >Privacy Policy</router-link
                            >.</span
                        >
                    </p>
                </div>

                <p class="desc-text" data-aos="fade">
                    You are solely responsible for your child’s/ward’s use of
                    the Service and for his/her agreement to and compliance with
                    the Terms. You agree to be bound by all the actions of your
                    child/ward, and all linked Child Account users in respect of
                    the use of the Service as if they were your actions (whether
                    or not these were in fact authorised by you).
                </p>

                <div class="desc-text" data-aos="fade">
                    <span>
                        <b class="is-italic">Account information</b>. When you
                        register for an Account, you may be asked to provide us
                        with certain information (including your personal data).
                        You represent that all the information you provide to us
                        on registration or at any time is:
                    </span>

                    <p class="is-flex">
                        <span class="mr-4">-</span>
                        <span
                            >true, accurate, current and complete, and that you
                            will promptly update such information on your
                            Account to ensure that it remains so at all times;
                            and</span
                        >
                    </p>
                    <p class="is-flex">
                        <span class="mr-4">-</span>
                        <span
                            >owned by you, and/or that you have the right to use
                            and disclose the same, or allow us to do so in
                            accordance with the
                            <router-link to="/privacy"
                                >Privacy Policy</router-link
                            >.</span
                        >
                    </p>
                </div>

                <p class="desc-text" data-aos="fade">
                    You agree that we may collect, use and process personal data
                    in accordance with our
                    <router-link to="/privacy">Privacy Policy</router-link>.
                </p>

                <p class="desc-text" data-aos="fade">
                    If you provide any information that is untrue, inaccurate,
                    not current or incomplete, or if we have reasonable grounds
                    to suspect so, we have the right to suspend or terminate
                    your Account.
                </p>

                <p class="desc-text" data-aos="fade">
                    <b class="is-italic">Login Codes</b>. You are responsible
                    for safeguarding your Account login email address and
                    password (“<b>Login Codes</b>”) and keeping them
                    confidential, and you shall be solely and fully liable for
                    any disclosure or unauthorised use thereof. You must not
                    share your Login Codes with anyone else or allow anyone else
                    to access and/or use the Service using your Login Codes. Any
                    use of and/or access to the Service referable to your Login
                    Codes shall be deemed to be use of and/or access to the
                    Service by you.
                </p>

                <p class="desc-text" data-aos="fade">
                    If you are aware of any actual or suspected unauthorised
                    use(s) of your Account, or unauthorised disclosure of your
                    Login Codes, please promptly notify us using the contact
                    information below.
                </p>

                <h3 class="title-text" data-aos="fade-right">
                    3. CONSENT TO USE DATA
                </h3>

                <p class="desc-text" data-aos="fade">
                    <b class="is-italic">Account Data</b>. You are responsible
                    for all data (including configuration settings and/or other
                    content) that you save under, use and/or generate in
                    connection with your Account(s). We reserve the right to
                    review all Account Data on the App and to remove any Account
                    Data for any reason in our sole discretion without notice.
                    You hereby grant us a non-exclusive right to use and process
                    Your Data for the purpose of providing, supporting,
                    maintaining and updating the Service. You warrant that the
                    use and/or reproduction by us of Account Data that you
                    submit or upload to the App will not violate or infringe the
                    rights of any third party.
                </p>

                <p class="desc-text" data-aos="fade">
                    You agree that we may continue to retain or delete Account
                    Data on our servers after the termination of your Account in
                    accordance with our data retention policies and applicable
                    laws.
                </p>

                <p class="desc-text" data-aos="fade">
                    Although we perform regular routine backups of data, we are
                    not responsible for the preservation or backup of Account
                    Data or for any of Account Data that may be lost, corrupted,
                    deleted or irrecoverable.
                </p>

                <p class="desc-text" data-aos="fade">
                    You acknowledge that Account Data on the App is only
                    available for viewing through the Service, and not available
                    for printing, copying, exporting or downloading in any other
                    format, and that you will not rely on the App to store
                    Account Data.
                </p>

                <p class="desc-text" data-aos="fade">
                    <b class="is-italic">Personal data</b>. Notwithstanding the
                    above, to the extent that any data provided by you
                    (including Account Data) personally identifies you, such
                    data shall be subject to our Privacy Policy [insert
                    hyperlink] and applicable privacy laws, which shall prevail
                    over these Terms of Service in the event of any conflict and
                    inconsistency.
                </p>

                <p class="desc-text" data-aos="fade">
                    <b class="is-italic">Non-personal data</b>. You agree that
                    we may collect and use technical data and related
                    information, including technical information about your
                    device, system and application software, and peripherals,
                    that is gathered periodically to facilitate the provision of
                    software updates, product support, and other services to you
                    (if any) related to the App. We may use this information, as
                    long as it is in a form that does not personally identify
                    you, to improve our products or to provide services or
                    technologies to you.
                </p>

                <p class="desc-text" data-aos="fade">
                    <b class="is-italic">Unsolicited submissions</b>. Please do
                    not send us any unsolicited ideas, suggestions, or proposals
                    if you expect to receive a reward, benefit or payment for
                    them or if you want to own or claim rights in them, so as to
                    prevent misunderstandings or disputes if we develop new
                    functionalities and features for the Service which you
                    perceive to be similar or even identical to your idea,
                    suggestion or proposal.
                </p>

                <p class="desc-text" data-aos="fade">
                    You acknowledge and agree that except for any personal,
                    individually identifying information which we have
                    specifically agreed with you to keep confidential in writing
                    in the Privacy Policy, any questions, comments, suggestions,
                    ideas, feedback, testimonials, reviews or other information
                    about the Service (“<b>Submissions”</b>) provided by you to
                    us (whether through email, phone, or any other means) shall
                    not be considered your confidential information or otherwise
                    proprietary to you, and that we shall be entitled to the
                    unrestricted use, reproduction, disclosure, dissemination
                    and exploitation of these Submissions for any purpose,
                    commercial or otherwise (including for marketing purposes)
                    without acknowledgement or compensation to you. We shall
                    have no obligations concerning your Submissions, contractual
                    or otherwise (including any obligation to keep your
                    Submissions confidential).
                </p>

                <p class="desc-text" data-aos="fade">
                    You represent and warrant that your Submissions do not
                    violate the law nor anyone's rights (whether at law or under
                    contract) nor consist of or contain software viruses,
                    solicitation or any form of ‘spam’. You may not use a false
                    email address, impersonate any person or entity, or
                    otherwise mislead us as to the origin of a Submission. You
                    represent and warrant that you own all of the rights to your
                    Submissions and that their use thereof by us as in
                    accordance with this Agreement will not infringe the rights
                    of any third party.
                </p>

                <h3 class="title-text" data-aos="fade-right">
                    4. LICENCE AND USE RESTRICTIONS
                </h3>

                <p class="desc-text" data-aos="fade">
                    <b class="is-italic">Licence</b>. We grant you a personal,
                    revocable, non-exclusive, non-transferable, limited right
                    and licence to access and use the Service (including to
                    download and use the App on a mobile device owned and
                    controlled by you and/or your parent/legal guardian, as
                    applicable) strictly in accordance with the Terms and
                    applicable laws.
                </p>

                <div class="desc-text" data-aos="fade">
                    <span>
                        <b class="is-italic">Use Restrictions</b>. You may not
                        do or attempt to do any of the following while accessing
                        and/or using the Service:
                    </span>

                    <p class="is-flex">
                        <span class="mr-4">(a)</span>
                        <span
                            >decompile, reverse engineer, disassemble, attempt
                            to derive the source code of and/or decrypt the
                            Service (except to the extent that applicable law
                            expressly permits despite this limitation);</span
                        >
                    </p>

                    <p class="is-flex">
                        <span class="mr-4">(b)</span>
                        <span
                            >copy, reproduce, modify, adapt, translate, publish,
                            display, distribute, sell, lease or otherwise
                            exploit or use the Service (or any content therein)
                            for any commercial, revenue generating or other
                            purposes for which they are not designed or
                            intended;</span
                        >
                    </p>

                    <p class="is-flex">
                        <span class="mr-4">(c)</span>
                        <span
                            >do anything that would disrupt, alter, destroy,
                            impair, interfere with, overburden or limit the
                            functionality, operation or security of the Service
                            (or the networks or services connected thereto), or
                            interfere with another user’s use and enjoyment
                            thereof, including by hacking into the Service, or
                            transmitting any virus or malicious code;</span
                        >
                    </p>

                    <p class="is-flex">
                        <span class="mr-4">(d)</span>
                        <span
                            >scan or test the vulnerability of any system or
                            network or breach or circumvent any security or
                            authentication measures;
                        </span>
                    </p>

                    <p class="is-flex">
                        <span class="mr-4">(e)</span>
                        <span
                            >attempt to bypass any measures designed to prevent
                            or restrict access to the Service, or any portion
                            thereof;
                        </span>
                    </p>

                    <p class="is-flex">
                        <span class="mr-4">(f)</span>
                        <span
                            >except as may be the result of standard search
                            engine or Internet browser usage, use, launch,
                            develop or distribute any automated system, linking,
                            deep-linking, mirroring, framing or page-scraping
                            technology, robots, spiders, scripts, cheat utility,
                            scrapper, offline reader or similar processes or
                            technology to access, allow access to, copy,
                            distribute, display, monitor or enable the mass
                            download or extraction of data from the Service;
                        </span>
                    </p>

                    <p class="is-flex">
                        <span class="mr-4">(g)</span>
                        <span
                            >systematically retrieve data or other content from
                            the Service to create or compile, directly or
                            indirectly, a collection, compilation, database or
                            directory;
                        </span>
                    </p>

                    <p class="is-flex">
                        <span class="mr-4">(h)</span>
                        <span
                            >violate any applicable laws, rules or regulations
                            or engage in any fraudulent, unlawful, illegal,
                            criminal or tortious activity;
                        </span>
                    </p>

                    <p class="is-flex">
                        <span class="mr-4">(i)</span>
                        <span
                            >make the Service available over a network or other
                            environment permitting access or use by multiple
                            devices or users at the same time or to provide the
                            Service as a service bureau;
                        </span>
                    </p>

                    <p class="is-flex">
                        <span class="mr-4">(j)</span>
                        <span
                            >use the Service for creating a product, service or
                            software that is, directly or indirectly,
                            competitive with or in any way a substitute for the
                            Service;
                        </span>
                    </p>

                    <p class="is-flex">
                        <span class="mr-4">(k)</span>
                        <span
                            >use any of our proprietary or intellectual property
                            in the design, development, manufacture, licensing
                            or distribution of any applications, accessories or
                            devices for use with the Service;
                        </span>
                    </p>

                    <p class="is-flex">
                        <span class="mr-4">(l)</span>
                        <span
                            >create Accounts by automated means or under false
                            pretext;
                        </span>
                    </p>

                    <p class="is-flex">
                        <span class="mr-4">(m)</span>
                        <span>sell or otherwise transfer your Account; </span>
                    </p>

                    <p class="is-flex">
                        <span class="mr-4">(n)</span>
                        <span
                            >engage in any fraudulent, unlawful or illegal
                            activity; or
                        </span>
                    </p>

                    <p class="is-flex">
                        <span class="mr-4">(o)</span>
                        <span
                            >use any information obtained from the Service to
                            harass, abuse, or harm another person.
                        </span>
                    </p>
                </div>

                <h3 class="title-text" data-aos="fade-right">
                    5. INTELLECTUAL PROPERTY RIGHTS
                </h3>

                <p class="desc-text" data-aos="fade">
                    “<b class="is-italic">Intellectual Property Rights</b>”
                    means all intellectual property rights whether registered or
                    not, including rights in inventions, trademarks, patent
                    rights, registered designs, copyright and all rights of
                    whatever nature in computer programs.
                </p>

                <p class="desc-text" data-aos="fade">
                    “<b class="is-italic">Service Content</b>” means all content
                    on the Service including all source code, databases,
                    functionality, software, designs, audio, video, text,
                    photographs, graphics, features, “look”, “feel” and
                    “appearance” of or relating to the Service.
                </p>

                <p class="desc-text" data-aos="fade">
                    All Intellectual Property Rights in the Service and Service
                    Content belong to us and/or our licensors.
                </p>

                <p class="desc-text" data-aos="fade">
                    The Service Content is provided to you “AS IS” for your
                    personal use only and may not be used, copied, reproduced,
                    aggregated, distributed, transmitted, broadcast, displayed,
                    sold, licensed or otherwise exploited by you for any other
                    purposes whatsoever without our prior written consent.
                </p>

                <p class="desc-text" data-aos="fade">
                    You acknowledge that rights in the Service are licensed (not
                    sold) to you, and that you have no rights thereto other than
                    to use them in accordance with these Terms of Service. We
                    reserve all rights in the Service and Service Content not
                    expressly granted to you.
                </p>

                <h3 class="title-text" data-aos="fade-right">
                    6. LINKED SITES
                </h3>

                <p class="desc-text" data-aos="fade">
                    The Website or App may contain (or you may be sent) links to
                    third party websites outside our control ("<b
                        >Linked Sites</b
                    >"). We take no responsibility for content contained in any
                    Linked Sites and inclusion of links to Linked Sites do not
                    imply our approval or endorsement of such Linked Sites or
                    third-party services. Such links are provided for your
                    convenience and interest only. You use these Linked Sites
                    entirely at your own risk.
                </p>

                <h3 class="title-text" data-aos="fade-right">
                    7. ACCOUNT TERMINATION
                </h3>

                <p class="desc-text" data-aos="fade">
                    <b class="is-italic">Termination by you</b>. You may
                    terminate your Parent Account or a linked Child Account at
                    any time by accessing the Account Settings.
                </p>

                <p class="desc-text" data-aos="fade">
                    Upon termination of an Account, all rights granted to you
                    hereunder with respect to use of the App shall immediately
                    cease and terminate, and you must immediately cease access
                    to and use of the App and uninstall, delete or remove the
                    App from your device(s).
                </p>

                <p class="desc-text" data-aos="fade">
                    Termination of a Parent Account will automatically terminate
                    all linked Child Accounts. Termination of a Child Account
                    will be immediately make the related data unavailable, and
                    will be removed from the database after 30 days.
                </p>

                <p class="desc-text" data-aos="fade">
                    <b class="is-italic">Termination by us</b>. WITHOUT LIMITING
                    ANY OTHER PROVISION OF THIS AGREEMENT, WE RESERVE THE RIGHT
                    TO, IN OUR SOLE AND ABSOLUTE DISCRETION AND WITHOUT PRIOR
                    NOTICE OR LIABILITY, RESTRICT, SUSPEND, DENY OR TERMINATE
                    YOUR ACCESS TO AND/OR USE OF THE SERVICE (INCLUDING WITHOUT
                    LIMITATION TO DEACTIVATE, DELETE, SUSPEND OR TERMINATE YOUR
                    ACCOUNT AND ALL INFORMATION RELATED OR CONTAINED THEREIN),
                    FOR ANY REASON OR FOR NO REASON AT ALL, INCLUDING WITHOUT
                    LIMITATION FOR BREACH OF ANY REPRESENTATION, WARRANTY OR
                    COVENANT CONTAINED IN THIS AGREEMENT, OR OF ANY APPLICABLE
                    LAW OR REGULATION.
                </p>

                <p class="desc-text" data-aos="fade">
                    <b class="is-italic">Effect of termination</b>. Any
                    provisions of the Terms that, in order to fulfil the purpose
                    of such provisions, need to survive the termination or
                    expiration of our agreement with you, shall be deemed to
                    survive for as long as necessary to fulfil such purposes.
                </p>

                <p class="desc-text" data-aos="fade">
                    You acknowledge and agree that even after the termination of
                    your Account, some information relating to your terminated
                    Account may remain in our records, and still be retained
                    and/or used by us as we deem necessary to comply with our
                    legal obligations, resolve disputes, enforce our agreements,
                    for evidentiary purposes and in accordance with our Privacy
                    Policy. For the avoidance of doubt, and save where required
                    under law, we are under no obligation to you to retain,
                    save, recover or provide you with any information and/or
                    User Data under your terminated Account, and shall be
                    entitled to delete your full profile, such information
                    and/or User Data upon cancellation or termination as we see
                    fit in the exercise of our sole discretion.
                </p>

                <h3 class="title-text" data-aos="fade-right">
                    8. App Store and Google Play
                </h3>

                <p class="desc-text" data-aos="fade">
                    The App is available for download through App Store or
                    Google Play, and the terms and conditions of App Store ("App
                    Store Terms") and Google Play ("Google Play Terms") apply
                    when you download the App from the App Store and Google Play
                    respectively.
                </p>

                <p class="desc-text" data-aos="fade">
                    In the event of any conflict or any inconsistency between
                    the App Store Terms/Google Play Terms on the one hand, and
                    any of the Terms on the other hand, the App Store
                    Terms/Google Play Terms (as applicable) shall prevail with
                    respect to your access and use of the App obtained via
                    download from the App Store/Google Play respectively.
                </p>

                <p class="desc-text" data-aos="fade">
                    In the event of any conflict or any inconsistency between
                    the App Store Terms/Google Play Terms on the one hand, and
                    any of the Terms on the other hand, the App Store
                    Terms/Google Play Terms (as applicable) shall prevail with
                    respect to your access and use of the App obtained via
                    download from the App Store/Google Play respectively.
                </p>

                <div class="desc-text" data-aos="fade">
                    <span>
                        <b class="is-italic">App Store</b>. If you download the
                        App from the App Store, you also acknowledge that:
                    </span>

                    <p class="is-flex">
                        <span class="mr-4">(a)</span>
                        <span
                            >The Terms are concluded between us and you only,
                            and not with Apple, and we, not Apple, are solely
                            responsible for the App and its content, to the
                            extent not effectively disclaimed.
                        </span>
                    </p>

                    <p class="is-flex">
                        <span class="mr-4">(b)</span>
                        <span
                            >The licence granted to you hereunder for the use of
                            the App is limited to a non-transferable licence to
                            use the App you download from the App Store on any
                            Apple-branded products that you own or control and
                            as permitted by the Usage Rules set forth in the
                            Apple Media Services Terms and Conditions (as may be
                            amended from time to time), except that the App may
                            be accessed, acquired and used by other accounts
                            associated with you via Family Sharing or volume
                            purchasing, if applicable.
                        </span>
                    </p>

                    <p class="is-flex">
                        <span class="mr-4">(c)</span>
                        <span
                            >Apple has no obligation whatsoever to furnish any
                            maintenance and support services with respect to the
                            App.
                        </span>
                    </p>

                    <p class="is-flex">
                        <span class="mr-4">(d)</span>
                        <span
                            >We are solely responsible for any product
                            warranties in respect of the App, whether express or
                            implied by law, to the extent not effectively
                            disclaimed. In the event of any failure of the App
                            to conform to any applicable warranty, you may
                            notify Apple, and Apple will refund the purchase
                            price (if any) for the App to you. To the maximum
                            extent permitted by applicable law, Apple has no
                            other warranty obligation whatsoever with respect to
                            the App, and any other claims, losses, liabilities,
                            damages, costs or expenses attributable to any
                            failure to conform to any warranty will be our sole
                            responsibility, to the extent not effectively
                            disclaimed.
                        </span>
                    </p>

                    <p class="is-flex">
                        <span class="mr-4">(e)</span>
                        <span
                            >We, not Apple, are responsible for addressing any
                            of your claims or any third party claims relating to
                            the App or your possession and/or use of the App,
                            including: (i) product liability claims; (ii) any
                            claim that the App fails to conform to any
                            applicable legal or regulatory requirement; and
                            (iii) claims arising under consumer protection or
                            similar legislation, including in connection with
                            the App’s use of the HealthKit and Homekit
                            frameworks (if applicable), subject to and in
                            accordance with the Terms.
                        </span>
                    </p>

                    <p class="is-flex">
                        <span class="mr-4">(f)</span>
                        <span
                            >In the event of any third party claim that the App
                            or your possession and use of the App infringes that
                            third party’s intellectual property rights, we, not
                            Apple, will be solely responsible for the
                            investigation, defense, settlement and discharge of
                            any such intellectual property infringement claim,
                            to the extent such responsibility is not effectively
                            disclaimed, and subject to and in accordance with
                            the Terms.
                        </span>
                    </p>

                    <p class="is-flex">
                        <span class="mr-4">(g)</span>
                        <span
                            >You represent and warrant that (i) you are not
                            located in a country that is subject to a U.S.
                            Government embargo, or that has been designated by
                            the U.S. Government as a “terrorist supporting”
                            country; and (ii) you are not listed on any U.S.
                            Government list of prohibited or restricted parties.
                        </span>
                    </p>

                    <p class="is-flex">
                        <span class="mr-4">(h)</span>
                        <span
                            >You must comply with applicable third party terms
                            of agreement when using the App.
                        </span>
                    </p>

                    <p class="is-flex">
                        <span class="mr-4">(i)</span>
                        <span
                            >Apple, and Apple’s subsidiaries, are third party
                            beneficiaries of the Terms, and upon your acceptance
                            thereof, Apple will have the right (and will be
                            deemed to have accepted the right) to enforce the
                            Terms against you as a third party beneficiary
                            thereof.
                        </span>
                    </p>

                    <p class="is-flex">
                        <span class="mr-4">(j)</span>
                        <span
                            >Complaints or claims with respect to the App should
                            be directed to us using the contact details
                            specified in <b>Section 13</b>.
                        </span>
                    </p>
                </div>

                <h3 class="title-text" data-aos="fade-right">9. DISCLAIMERS</h3>

                <p class="desc-text" data-aos="fade">
                    The extent of our liability under or in connection with the
                    Service (regardless of whether such liability arises under
                    tort, contract or otherwise and whether or not caused by
                    negligence or misrepresentation) shall be as set out in this
                    <b>Section 9</b>.
                </p>

                <p class="desc-text" data-aos="fade">
                    <b class="is-italic">No warranty</b>. THE WEBSITE, APP AND
                    SERVICE CONTENT ARE PROVIDED TO YOU 'AS IS', 'AS AVAILABLE',
                    WITHOUT REPRESENTATION, WARRANTY OR CONDITION OF ANY KIND,
                    WHETHER EXPRESS, IMPLIED OR STATUTORY (INCLUDING ANY IMPLIED
                    WARRANTY OR CONDITION OF MERCHANTABILITY OR SATISFACTORY
                    QUALITY, FITNESS FOR A PARTICULAR PURPOSE, NON-INFRINGEMENT,
                    COMPLIANCE WITH ANY DESCRIPTION OR ANY IMPLIED WARRANTY
                    ARISING FROM COURSE OF PERFORMANCE, COURSE OF DEALING, USAGE
                    OF TRADE OR OTHERWISE), ALL OF WHICH ARE EXPRESSLY
                    DISCLAIMED TO THE MAXIMUM EXTENT PERMITTED UNDER APPLICABLE
                    LAWS.
                </p>

                <p class="desc-text" data-aos="fade">
                    You acknowledge and agree that the entire risk arising out
                    of your access to and/or use of the Service remains solely
                    with you, to the maximum extent permitted by law.
                </p>

                <div class="desc-text" data-aos="fade">
                    <span>
                        Without limiting the foregoing and without prejudice to
                        any other disclaimers or limitations of liability under
                        the Terms, we make no representation or warranty:
                    </span>

                    <p class="is-flex">
                        <span class="mr-4">(a)</span>
                        <span
                            >that the Service will be always available,
                            uninterrupted, error-free or virus-free, or that
                            bugs and defects therein will be corrected, or that
                            we will provide any updates, patches, or fixes or
                            any maintenance support services;
                        </span>
                    </p>

                    <p class="is-flex">
                        <span class="mr-4">(b)</span>
                        <span
                            >that the transmission of any information through
                            the Service will be entirely private or secure;
                            and/or
                        </span>
                    </p>

                    <p class="is-flex">
                        <span class="mr-4">(c)</span>
                        <span
                            >as to the correctness, accuracy, completeness,
                            reliability, safety, timeliness, quality,
                            suitability or availability of the Service (or any
                            content therein).
                        </span>
                    </p>
                </div>

                <p class="desc-text" data-aos="fade">
                    By using the Service, you acknowledge and agree that
                    internet transmissions are never completely private or
                    secure, and that it is possible that the data on the Service
                    can be mistakenly released, lost, hacked or accessed by
                    unauthorized users. You further acknowledge that operation
                    of and access to the Service and/or Website may be
                    interrupted or interfered with as a result of technical
                    errors or issues or other factors outside of our control.
                </p>

                <p class="desc-text" data-aos="fade">
                    <b class="is-italic">No liability </b>. TO THE FULLEST
                    EXTENT PERMITTED BY LAW, IN NO EVENT SHALL WE OR OUR
                    DIRECTORS, EMPLOYEES, AGENTS, CONTRACTORS OR SUBSIDIARIES BE
                    LIABLE TO YOU OR ANY THIRD PARTY FOR ANY DIRECT, INDIRECT,
                    CONSEQUENTIAL, EXEMPLARY, INCIDENTAL, SPECIAL OR PUNITIVE
                    DAMAGES, INCLUDING WITHOUT LIMITATION LOSS OF PROFIT, LOSS
                    OF REVENUE, LOSS OF DATA, LOSS OF OPPORTUNITY OR OTHER
                    DAMAGES ARISING FROM OR IN CONNECTION WITH YOUR USE OF (OR
                    INABILITY TO USE) THE SERVICE OR ANY PART THEREOF, WHETHER
                    FORESEEABLE OR NOT, EVEN IF WE HAVE BEEN ADVISED OF THE
                    POSSIBLITY OF SUCH DAMAGES.
                </p>

                <div class="desc-text" data-aos="fade">
                    <span>
                        Without prejudice to the generality of the foregoing, we
                        shall not be liable for:
                    </span>

                    <p class="is-flex">
                        <span class="mr-4">(d)</span>
                        <span
                            >any use of or reliance by you or a third party on
                            the Service;
                        </span>
                    </p>

                    <p class="is-flex">
                        <span class="mr-4">(e)</span>
                        <span
                            >any use of the Service in a manner other than as
                            directed by us;
                        </span>
                    </p>

                    <p class="is-flex">
                        <span class="mr-4">(f)</span>
                        <span
                            >any third party content, product, service, website,
                            application, warranty or representation;
                        </span>
                    </p>

                    <p class="is-flex">
                        <span class="mr-4">(g)</span>
                        <span
                            >any act/omission, negligence, wilful default,
                            misconduct, fraud or breach of a third party;
                        </span>
                    </p>

                    <p class="is-flex">
                        <span class="mr-4">(h)</span>
                        <span
                            >any error, omission, defect, deficiency,
                            malfunction, unreliability, nonconformity, delay or
                            disruption in or of the Service or your inability to
                            use the same (including glitches, bugs, errors, or
                            inaccuracies of any kind);
                        </span>
                    </p>

                    <p class="is-flex">
                        <span class="mr-4">(i)</span>
                        <span
                            >unauthorised access to, disclosure of or alteration
                            of your information or any unauthorised, wrongful or
                            fraudulent use of your Account, as a result of the
                            unauthorised acts of third parties; or
                        </span>
                    </p>

                    <p class="is-flex">
                        <span class="mr-4">(j)</span>
                        <span
                            >any modification, suspension or discontinuance of
                            the Service, changes to these Terms, or any other
                            action taken by us with respect to your Account(s)
                            or pursuant to the Terms.
                        </span>
                    </p>
                </div>

                <p class="desc-text" data-aos="fade">
                    <b class="is-italic">Liability limit</b>. NOWITHSTANDING
                    ANYTHING TO THE CONTRARY CONTAINED HEREIN, IN THE EVENT THAT
                    WE ARE LIABLE TO YOU FOR DAMAGES DESPITE THE PROVISIONS IN
                    THIS AGREEMENT, YOU AGREE THAT OUR TOTAL LIABILITY TO YOU
                    (TO THE EXTENT NOT EFFECTIVELY DISCLAIMED) FOR ANY CAUSE
                    WHATSOEVER AND REGARDLESS OF THE FORM OF THE ACTION, WILL AT
                    ALL TIMES BE LIMITED TO AND SHALL NOT EXCEED THE AMOUNT OF
                    SINGAPORE DOLLARS FIFTY (S$50.00) IN THE AGGREGATE.
                </p>

                <p class="desc-text" data-aos="fade">
                    You agree that exclusion of warranties, limitation of
                    liability, and remedies in the Terms are reasonable to
                    allocate risks between you and us and enable us to provide
                    the Service to you for free or at lower fees than we
                    otherwise could.
                </p>

                <h3 class="title-text" data-aos="fade-right">10. INDEMNITY</h3>

                <div class="desc-text" data-aos="fade">
                    <span>
                        You agree to defend, fully indemnify and hold us, our
                        subsidiaries, related companies, affiliates, and
                        our/their respective officers, agents, partners and
                        employees, harmless from and against, any and all
                        losses, damages, liabilities, actions, costs, expenses,
                        penalties, claims and/or demands, (including statutory
                        liability and liability to third parties, economic loss,
                        accounting fees, and court and legal costs assessed on a
                        solicitor-and-client basis), due to or arising out of,
                        whether directly or indirectly, from:
                    </span>

                    <p class="is-flex">
                        <span class="mr-4">(a)</span>
                        <span
                            >your acts, omissions, negligence, wilful default,
                            mistake, misconduct, dishonesty and/or fraud;
                        </span>
                    </p>

                    <p class="is-flex">
                        <span class="mr-4">(b)</span>
                        <span>your breach of the Terms; </span>
                    </p>

                    <p class="is-flex">
                        <span class="mr-4">(c)</span>
                        <span
                            >any use of your Account(s) referable to your Login
                            Codes;
                        </span>
                    </p>

                    <p class="is-flex">
                        <span class="mr-4">(d)</span>
                        <span
                            >any breach or violation by you of any applicable
                            laws or third party rights; and/or
                        </span>
                    </p>

                    <p class="is-flex">
                        <span class="mr-4">(e)</span>
                        <span
                            >your instructions, and/or our acting in good faith
                            and taking or refusing to take action based thereon.
                        </span>
                    </p>
                </div>

                <p class="desc-text" data-aos="fade">
                    Notwithstanding the foregoing, we reserve the right, at your
                    expense, to assume the exclusive defence and control of any
                    matter for which you are required to indemnify us, and you
                    agree to cooperate fully, at your expense, with our defense
                    of such claims.
                </p>

                <p class="desc-text" data-aos="fade">
                    You will be liable for any attorney’s fees and costs (on a
                    solicitor-and-client basis) if we have to take any legal
                    action to enforce these Terms.
                </p>

                <h3 class="title-text" data-aos="fade-right">11. DISPUTES</h3>

                <p class="desc-text" data-aos="fade">
                    If for whatever reason you are dissatisfied with the
                    Service, you must send a written notice to us by email in
                    order to provide us with the opportunity to attempt in good
                    faith to resolve the issue with you. If we are unable to
                    resolve the issue, you may pursue resolution of the issue as
                    set forth below.
                </p>

                <p class="desc-text" data-aos="fade">
                    The Terms and all matters relating to your access and use of
                    the Service shall be subject to, governed by and construed
                    in accordance with the laws of Singapore, and you hereby
                    submit to the exclusive jurisdiction of the Singapore courts
                    for the purposes of any legal action or proceedings brought
                    by us against you (including any summons, motion or
                    petition, and any application to court for injunctive,
                    equitable and other relief).
                </p>

                <p class="desc-text" data-aos="fade">
                    You agree that we shall be entitled at our election to refer
                    any claim not exceeding Singapore Dollars Twenty Thousand
                    (S$20,000) (or the highest limit prescribed by law) to the
                    State Courts’ Small Claims Tribunal. You agree at our
                    request to execute all documents (including executing where
                    applicable a Memorandum of Consent) and to perform all acts
                    necessary to enable such dispute to be referred to the Small
                    Claims Tribunal at our election.
                </p>

                <p class="desc-text" data-aos="fade">
                    You hereby agree that any dispute, controversy or claim that
                    you may bring against us arising out of or in connection
                    with the Service, the Terms and/or your agreement with us,
                    including any question you may raise regarding its
                    existence, validity, breach or termination, shall be
                    referred to and finally resolved by arbitration in Singapore
                    at the Singapore International Arbitration Centre
                    (“<b>SIAC”</b>) or at such other venue in Singapore as the
                    parties may agree in writing in accordance with the SIAC
                    Rules. In relation to such arbitration: (a) the law of the
                    arbitration shall be the Singapore International Arbitration
                    Act (Cap. 143A); (b) the tribunal shall consist of a single
                    arbitrator to be appointed by the Chairman of the Singapore
                    International Arbitration Centre; (c) the language of the
                    arbitration shall be English; (d) the parties hereto
                    undertake to keep the arbitration proceedings and all
                    information, pleadings, documents, evidence and all matters
                    relating thereto confidential; and (e) no arbitration will
                    be combined with another without the prior written consent
                    of all parties to all affected arbitrations.
                </p>

                <p class="desc-text" data-aos="fade">
                    Application of the United Nations Convention on Contracts
                    for the International Sale of Goods is excluded from this
                    Agreement. Additionally, application of the Uniform Computer
                    Information Transaction Act is excluded from this Agreement.
                </p>

                <p class="desc-text" data-aos="fade">
                    In no event shall any claim, action or proceeding by you
                    related in any way to the Service (including your use
                    thereof) be brought by you against us more than two (2)
                    years after the cause of action arose.
                </p>

                <h3 class="title-text" data-aos="fade-right">12. GENERAL</h3>

                <p class="desc-text" data-aos="fade">
                    <b class="is-italic">Force Majeure</b>. We shall not be
                    liable for any failure or delay in the performance of our
                    obligations or the provision of the Service that is due, in
                    whole or in part, directly or indirectly to an event,
                    circumstance or failure which is beyond our reasonable
                    control including (a) acts of God, nature, court or
                    government; (b) strikes, lockouts, industrial action or
                    labour disputes; (c) riots, civil unrest, war or threat of
                    war, criminal or terrorist acts; (d) epidemic, quarantine
                    restrictions; (e) disruption to supply lines, and general
                    failure or impossibility of the use of aircraft, shipping,
                    aircraft, motor transport or other means of public or
                    private transport, or political interference with the normal
                    operations of any party; (f) utility failures, power
                    outages, failure or interruption in public or private
                    telecommunication networks, communications channels or
                    information systems used in the provision of services; (g)
                    acts or omissions of a third party service provider or party
                    for whom we are not responsible; (h) delay, failure or
                    interruption in, or unavailability of, third party services,
                    applications and/or websites; and (i) viruses, other
                    malicious computer codes or the hacking by any party of the
                    Website and/or the App, third party services, applications
                    and/or websites.
                </p>

                <p class="desc-text" data-aos="fade">
                    <b class="is-italic">Records</b>. You agree that our
                    records, whether stored in printed or electronic form, shall
                    be binding on you for all purposes whatsoever and shall be
                    conclusive evidence of the contents thereof. You hereby
                    agree that all such records are admissible in evidence and
                    that you shall not challenge or dispute the admissibility,
                    reliability, accuracy or authenticity of the contents of
                    such records and hereby waive any of your rights (if any) to
                    so object.
                </p>

                <p class="desc-text" data-aos="fade">
                    <b class="is-italic">Waiver</b>. Any failure by us to
                    exercise or enforce any right or provision of the Terms
                    shall not operate as a waiver of such right or provision.
                </p>

                <p class="desc-text" data-aos="fade">
                    <b class="is-italic">Headings</b>. Headings and
                    section/paragraph titles in the Terms are for convenience
                    only and have no legal or contractual effect.
                </p>

                <p class="desc-text" data-aos="fade">
                    <b class="is-italic">Assignment</b>. Our Agreement with you
                    and your Account may not be assigned by you without our
                    express written consent. We may assign any or all of its
                    rights and obligations under our agreement with you to any
                    other party at any time, without your consent.
                </p>

                <p class="desc-text" data-aos="fade">
                    <b class="is-italic">Severance</b>. If any provision of the
                    Agreement is found to be illegal, void or unenforceable
                    under any applicable law or if any court of competent
                    jurisdiction in a final decision so determines, the
                    Agreement shall continue in force save that such provision
                    shall be deemed to be deleted.
                </p>

                <p class="desc-text" data-aos="fade">
                    <b class="is-italic">Construction</b>. You agree that the
                    Agreement will not be construed against us by virtue of
                    having drafted it.
                </p>

                <p class="desc-text" data-aos="fade">
                    <b class="is-italic">Electronic signing</b>. You hereby
                    waive any and all defences you may have based on the
                    electronic form of the Agreement with you and the lack of
                    signing by the parties hereto to execute such Agreement.
                </p>

                <p class="desc-text" data-aos="fade">
                    <b class="is-italic">Notices</b>. Any notices given to you
                    shall be given to the email address you provided under your
                    Account. Notice shall be deemed to be validly given
                    twenty-four (24) hours after the email is sent, unless we
                    are notified that the email address is invalid. We may also
                    choose to send notices by postal mail.
                </p>

                <p class="desc-text" data-aos="fade">
                    <b class="is-italic">Interpretation</b>. In the Terms
                    (including the Privacy Policy) “<b>include”</b> (and its
                    derivatives, such as “<b>including”</b>
                    and “includes”) means to include without limitation and
                    every occurrence of this term shall be construed as defined
                    aforesaid, whether or not capitalised.
                </p>

                <h3 class="title-text" data-aos="fade-right">13. CONTACT US</h3>

                <div class="desc-text" data-aos="fade">
                    <span>
                        You may contact us if you have any questions relating to
                        the Service or the Terms by using any of the following
                        methods:
                    </span>

                    <p class="is-flex">
                        <span class="mr-4">(a)</span>
                        <span
                            >For customer service and support issues, by email
                            to hello@55mins.com
                        </span>
                    </p>

                    <p class="is-flex">
                        <span class="mr-4">(b)</span>
                        <span
                            >For service of legal notice which is required by
                            contract or any law or regulation, by email in
                            writing to hello@55mins.com. For the avoidance of
                            doubt, communications sent to any other email
                            address or to any support contacts or via in-app
                            communications will not constitute effective legal
                            notice to us or any of our officers, employees,
                            agents and/or representatives.
                        </span>
                    </p>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import marked from 'marked';

export default {
    methods: {
        marked,
    },
};
</script>

<style lang="scss" scoped>
@import '@/assets/styles/page/Term.scss';
</style>
